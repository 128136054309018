import { NavLink } from "react-router-dom";

import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import Logo from '../../images/logo.jpg';

import CartLink from "../CartLink/CartLink";

import "./Header.css";

const items: MenuProps['items'] = [
  {
    label: <NavLink to={"/"} className="header_nav_item">О КОМПАНИИ</NavLink>,
    key: '0',
  },
  {
    label: <NavLink to={"/services"} className="header_nav_item">УСЛУГИ</NavLink>,
    key: '1',
  },
  {
    label: <NavLink to={"/catalog"} className="header_nav_item">КАТАЛОГ</NavLink>,
    key: '2',
  },
  {
    label: <NavLink to={"/advantages"} className="header_nav_item">ПРЕИМУЩЕСТВА</NavLink>,
    key: '3',
  },
  {
    label: <NavLink to={"/contacts"} className="header_nav_item">КОНТАКТЫ</NavLink>,
    key: '4',
  },
  {
    label: <CartLink text="КОРЗИНА" />,
    key: '5',
  },
];

const Header = () => {

  return (
    <header className="header outline">
      <div className="header-top">
        <a href="/">
          <img src={Logo} alt='Логотип Абсолют Медиа' className='header_logo'></img>
        </a>
        <div className="header-top-right">
          <div className="header-top-right-burger">
            <Dropdown menu={{ items }} trigger={['click']}>
              <a href="#" onClick={(e) => e.preventDefault()}>
                <Space>
                  <MenuOutlined style={{ fontSize: '24px' }} />
                </Space>
              </a>
            </Dropdown>
          </div>
          <a href="/contacts" className='header-address link'>ул. Рашпилевская, 321</a>
          <a href="tel:+79280364901" className="header-phone link">+7 (928) 036-49-01</a>
        </div>
      </div>
      <div className='header-bottom'>
        <nav className="header_nav">
          <NavLink to={"/"} className="header_nav_item">О КОМПАНИИ</NavLink>
          <NavLink to={"/services"} className="header_nav_item">УСЛУГИ</NavLink>
          <NavLink to={"/catalog"} className="header_nav_item">КАТАЛОГ</NavLink>
          <NavLink to={"/advantages"} className="header_nav_item">ПРЕИМУЩЕСТВА</NavLink>
          <NavLink to={"/contacts"} className="header_nav_item">КОНТАКТЫ</NavLink>
          <CartLink />
        </nav>
        <div className="header-contacts">
          <a href="/contacts" className='header-address-full header-address'>г. Краснодар, ул. Рашпилевская, 321</a>
          <a href="/contacts" className='header-address-short header-address'>ул. Рашпилевская, 321</a>
          <a href="tel:+79280364901" className="header-phone">+7 (928) 036-49-01</a>
        </div>
      </div>
    </header>
  );
}

export default Header;