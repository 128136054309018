import {createContext, Dispatch, SetStateAction, useState} from "react";
import { Outlet } from "react-router-dom";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import "./Layout.css";

export type cartType = {
  key: number,
  name: string,
  small_image: string,
  price: number,
  count: number
}[];

export type LayoutContextType = {
  cart: cartType;
  setCart: Dispatch<SetStateAction<cartType>>
};

export const LayoutContext = createContext<LayoutContextType>({ cart: [], setCart: () => {} });

const Layout = () => {

  const [cart, setCart] = useState<cartType>(() => {
    const result = window.localStorage.getItem('cart');
    if (result) {
      return  JSON.parse(result);
    } else {
      window.localStorage.setItem('cart', JSON.stringify([]));
      return [];
    }
  });

	return (
    <LayoutContext.Provider value={{ cart, setCart }}>
      <div className="container">
        <Header />
      </div>
      <main className="main">
        <Outlet />
      </main>
      <div className="container">
        <Footer />
      </div>
    </LayoutContext.Provider>
	);
};

export default Layout;
