import { FC, useContext, useState } from "react";

import api from "../../api/api";

import { Button, Image, Space, Table, Typography, Modal, Form, Input, message, Empty } from "antd";
import type { TableProps } from 'antd';
import { MailOutlined, DeleteOutlined, PlusOutlined, MinusOutlined} from "@ant-design/icons";

import { LayoutContext } from "../Layout/Layout";

import { BASE_URL } from "../../api/constants";

import "./Cart.css";

interface CartProduct {
  key: number;
  name: string;
  small_image: string;
  price: number;
  currency?: string;
  count: number;
}

// interface ShortCartProduct {
//   product_id: number;
//   count: number;
//   price: number;
// }

interface Order {
  name: string;
  email: string;
  phone: string;
  cart: string;
}

interface FormValues {
  name: string;
  email: string;
  phone: string;
}

const validateMessages = {
  required: 'Укажите ${label}',
  types: {
    email: 'Укажите корректный E-mail',
  }
};

const { Text } = Typography;

const Cart: FC = () => {

  const { cart, setCart } = useContext(LayoutContext);

  // const [confirmLoading, setConfirmLoading] = useState(false);

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<FormValues>();
  const [open, setOpen] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const showSendSuccessMessage = () => {
    messageApi.open({
      type: 'success',
      content: 'Ваш заказ отправлен',
    });
  };

  const showSendErrorMessage = () => {
    messageApi.open({
      type: 'error',
      content: 'Заказ не отправлен, попробуйте ещё раз',
    });
  };

  const showDelFromCartMessage = () => {
    messageApi.open({
      type: 'warning',
      content: 'Товар удалён из корзины',
    });
  };

  const onCreate = async (values: FormValues) => {

    const shortCart = cart.map((item)=>{
      return {
        product_id: item.key,
        name: item.name,
        count: item.count,
        price: item.price,
        image: item.small_image,
      }
    });

    const order:Order = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      cart: JSON.stringify(shortCart)
    }

    const result = await api.postOrder(order);

    if (result.result) {
      window.localStorage.setItem('cart', JSON.stringify([]));
      setCart([]);
      setFormValues(values);
      setOpen(false);
      showSendSuccessMessage();
    } else {
      showSendErrorMessage();
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  // const handleOk = () => {
  //   // setModalText('The modal will be closed after two seconds');
  //   setConfirmLoading(true);
  //   setTimeout(() => {
  //     setOpen(false);
  //     setConfirmLoading(false);
  //   }, 2000);
  // };

  const handleCancel = () => {
    setOpen(false);
  };

  const rawDelete = (key: number) => {
    const newCart = cart.filter((item) => item.key !== key);
    window.localStorage.setItem('cart', JSON.stringify(newCart));
    setCart(newCart);
    showDelFromCartMessage();
  };

  const rawCountChange = (key: number, changeNumber: number) => {

    const newCart = cart.map(item => {

      const newCount = item.count + changeNumber;

      if (item.key === key && newCount > 0 && newCount < 100) {
        return {
          ...item,
          count: newCount,
        };
      }

      return item;

    });

    window.localStorage.setItem('cart', JSON.stringify(newCart));
    setCart(newCart);

  };

  const columns: TableProps<CartProduct>['columns'] = [
    {
      title: 'Товар',
      dataIndex: 'name',
      render: (_, record) => <>
        <Space
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <a className="cart-link" href={`${BASE_URL + '/catalog/' + record.key}`}>
            <Space>
              <Image
                width={"35px"}
                src={`${BASE_URL}/${record.small_image}`}
                preview={false}
                style={{
                  marginRight: '10px',
                }}
              />
              <Text className="cart-raw-name">{ record.name }</Text>
            </Space>
          </a>
          {/*<Button size="small" onClick={() => rawDelete(record.key)}>*/}
          {/*  <DeleteOutlined*/}
          {/*    style={{ fontSize: '16px' }}*/}
          {/*  />*/}
          {/*</Button>*/}
        </Space>
      </>,
    },
    {
      title: 'Кол.',
      dataIndex: 'count',
      align: 'right',
      render: (_, record) => <Space>
        {/*<Button size="small" onClick={() => rawCountChange(record.key, -1)}>*/}
        {/*  <MinusOutlined*/}
        {/*    style={{ fontSize: '10px' }}*/}
        {/*  />*/}
        {/*</Button>*/}
        <Text className="cart-item-count">{record.count}</Text>
        {/*<Button size="small" onClick={() => rawCountChange(record.key, 1)}>*/}
        {/*  <PlusOutlined*/}
        {/*    style={{ fontSize: '10px' }}*/}
        {/*  />*/}
        {/*</Button>*/}
      </Space>
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      align: 'right',
      render: (_, record) => <span>{ `${(record.price * record.count).toFixed(2)}${record.currency && record.currency!== "р." ? record.currency : "₽"}` }</span>
    },
    {
      // title: 'Действия',
      dataIndex: 'actions',
      align: 'right',
      render: (_, record) => <Space className="cart-raw-actions">
        <Button size="small" onClick={() => rawCountChange(record.key, -1)}>
          <MinusOutlined
            style={{ fontSize: '16px' }}
          />
        </Button>
        <Button size="small" onClick={() => rawCountChange(record.key, 1)}>
          <PlusOutlined
            style={{ fontSize: '16px' }}
          />
        </Button>
        <Button size="small" onClick={() => rawDelete(record.key)}>
          <DeleteOutlined
            style={{ fontSize: '16px' }}
          />
        </Button>
      </Space>
    },
  ];

  return (
    <div className="container cart">
      {contextHolder}
      <h1>КОРЗИНА</h1>
      {
        <Table<CartProduct>
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Здесь пока пусто.." /> }}
          pagination={false}
          columns={columns}
          dataSource={cart}
          bordered
        />
      }
      <Space
        style={{
          display: 'flex',
          justifyContent: "flex-end"
        }}
      >
        <Button
          type="primary"
          size="large"
          icon={<MailOutlined />}
          onClick={showModal}
          disabled={cart.length === 0}
        >
          {"Отправить заказ"}
        </Button>
      </Space>
      <Modal
        open={open}
        title="Представьтесь, пожалуйста:"
        okText="Отправить"
        cancelText="Отмена"
        okButtonProps={{
          autoFocus: true,
          htmlType: 'submit'
        }}
        onCancel={handleCancel}
        destroyOnClose
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form_in_modal"
            // initialValues={{ modifier: 'public' }}
            clearOnDestroy
            onFinish={(values) => onCreate(values)}
            validateMessages={validateMessages}
            variant="filled"
          >
            {dom}
          </Form>
        )}
      >
        <div className="form-inputs">
          <Form.Item
            name="name"
            // label="Ваше имя"
            rules={[{
              required: true,
              message: 'Вы не представились',
              // whitespace: true
            }]}
          >
            <Input
              placeholder="ФИО"
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="email"
            // label="E-mail"
            rules={[
              {
                type: 'email',
                // message: 'Ваш E-mail',
              },
              {
                required: true,
                message: 'Укажите ваш E-mail',
              },
            ]}
          >
            <Input
              placeholder="E-mail"
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="phone"
            // label="Телефон"
            rules={[{
              required: true,
              message: 'Укажите ваш телефон'
            }]}
          >
            <Input
              placeholder="Телефон"
              style={{ width: '100%' }}
              allowClear
            />
          </Form.Item>
          <Text>
            Наш менеджер свяжется с вами для уточнения деталей заказа.
          </Text>
        </div>
      </Modal>
    </div>

  );
};

export default Cart;
