import {useContext, useEffect, useState} from 'react';
import { useParams } from "react-router-dom";

import { EyeOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import {Button, Image, message} from 'antd';

import { Product as ProductType } from "../../data/types";

import { LayoutContext } from "../Layout/Layout";

import api from "../../api/api";
import { BASE_URL } from "../../api/constants";

import './Product.css';

const Product = () => {

  const { cart, setCart } = useContext(LayoutContext);

  const { product_id } = useParams();

  const [product, setProduct] = useState<ProductType | null>(null);

  const getProduct = async (product_id: number) => {

    const result = await api.getProductById(product_id);

    if (result.data) {
      setProduct(result.data[0]);
    }

  }

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Товар добавлен в корзину',
    });
  };

  const addToCart = () => {
    if (product) {
      if (!inCart()) {
        setCart((cart) => {
          window.localStorage.setItem('cart', JSON.stringify(
            [...cart,
              {
                key: product.product_id,
                name: product.name,
                small_image: product.small_image,
                price: product.price,
                currency: product.currency,
                count: 1,
              }
            ]));
          return [
            ...cart,
            {
              key: product.product_id,
              name: product.name,
              small_image: product.small_image,
              price: product.price,
              currency: product.currency,
              count: 1,
            }
          ];
        });
        success();
      }
    }
  }

  const inCart = () => {
    if (product) {
      return cart.some(o => o.key === product.product_id);
    }
  }

  useEffect(() => {

    void getProduct(Number(product_id));

  }, [product_id]);

  return (
    <>
      { product &&
        <div className="container product">
          {contextHolder}
          <div className = 'product-page'>
            <div className="product-page-left">
              <div className="product-page-images">
                {
                  <Image.PreviewGroup>
                    {
                      product.images.map((src, index) => (
                        <Image
                          key={index}
                          width={index === 0 ? "100%" : "22%"}
                          src={`${BASE_URL}/${src}`}
                          className={index === 0 ? "product-page-main-image" : "product-page-other-image"}
                          preview={{
                            mask:
                            <>
                              <EyeOutlined
                                style={{marginRight: index === 0 ? '5px' : '0px'}}
                              />
                              <span>{index === 0 ? "Просмотр" : ""}</span>
                            </>,
                            maxScale: 1.5
                          }}
                        />
                      ))
                    }
                  </Image.PreviewGroup>
                }
              </div>
            </div>
            <div className='product-page-right'>
              <div className='product-page-right-top'>
                <p className = 'product-page-title'>{ product.name }</p>
                <div className="product-page-button-price-container">
                  <span className='product-page-price'>{`${product.price} ${product.currency && product.currency!== "р." ? product.currency : "₽"}`}</span>
                  <Button
                    type="primary"
                    size="large"
                    icon={<ShoppingCartOutlined />}
                    onClick={addToCart}
                    disabled={inCart()}
                  >{inCart() ? "В корзине" : "В корзину"}</Button>
                </div>
              </div>
            </div>
          </div>
          { product.content &&
            <div className='product-page-info'>
              {
                product.similar.length > 0 &&
                <>
                  <p className = 'product-page-title'>Варианты исполнения:</p>
                  <div className="product-page-similar-container">
                    {
                      product.similar.map((src, index) => (
                        <a href={`${BASE_URL}/catalog/${src.product_id}`}>
                          <Image
                            key={index}
                            width={50}
                            src={`${BASE_URL}/${src.small_image}`}
                            preview={false}
                          />
                        </a>
                      ))
                    }
                  </div>
                </>
              }
              <p className = 'product-page-info-title'>Описание:</p>
              <div dangerouslySetInnerHTML={{__html: product.content}} ></div>
              {product.prints.length > 0 &&
                <>
                  <p className = 'product-page-info-title'>Способы нанесения:</p>
                  <ul>
                    {
                      product.prints.map((print, index) => (
                        <li key={ index } className='my-list'>{ print }</li>
                      ))
                    }
                  </ul>
                </>
              }
            </div>
          }
        </div>
      }
    </>
  );
}

export default Product;