import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from "antd";

import Layout from "./pages/Layout/Layout";
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import Catalog from "./pages/Catalog/Catalog";
import Cart from "./pages/Cart/Cart";
import Product from "./pages/Product/Product";
import Advantages from "./pages/Advantages/Advantages";
import Contacts from "./pages/Contacts/Contacts";
import Page404 from "./components/404/404";

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ConfigProvider theme={{
    components: {
      Button: {
        colorPrimary: '#d34d4d',
        colorPrimaryHover: '#c82020',
        colorPrimaryBorder: '#d34d4d',
        colorPrimaryBorderHover: '#c82020',
        colorPrimaryActive: '#c82020',
      },
    }
  }}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<About />} />
          <Route path="services/" element={<Services />} />
          <Route path="advantages/" element={<Advantages />} />
          <Route path="catalog/" element={<Catalog />} />
          <Route path="catalog/:product_id" element={<Product />} />
          <Route path="cart/" element={<Cart />} />
          <Route path="contacts/" element={<Contacts />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </ConfigProvider>
);
