import { FC, createContext, useEffect, useState, Dispatch, SetStateAction } from "react";

import { ProductList } from "../../components/ProductList/ProductList";
import api from "../../api/api";
import SearchString from "../../components/ParseXml/SearchString";
import { ProductList as ProductListType } from "../../data/types";

import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';

import "./Catalog.css";

const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return <a><DoubleLeftOutlined /></a>;
  }
  if (type === 'next') {
    return <a><DoubleRightOutlined /></a>;
  }
  return originalElement;
};

type FilterType = {
  page: number,
  searchValue: string[]
}

export type CatalogContextType = {
  filter: FilterType;
  setFilter: Dispatch<SetStateAction<FilterType>>
};

export const CatalogContext = createContext<CatalogContextType>({ filter: { page: 1, searchValue: []}, setFilter: () => {} });

const Catalog: FC = () => {

  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState<ProductListType>([]);

  const [filter, setFilter] = useState<FilterType>(() => {

    const newFilter: FilterType = { page: 1, searchValue: [] };
    const oldFilterString = window.localStorage.getItem('filter');

    if (oldFilterString) {

      const oldFilter = JSON.parse(oldFilterString);
      if (oldFilter) {

        if (oldFilter.page) {
          newFilter.page = oldFilter.page;
        } else {
          newFilter.page = 1;
        }
        if (oldFilter.searchValue) {
          newFilter.searchValue = oldFilter.searchValue;
        } else {
          newFilter.searchValue = [];
        }
      }

    }

    window.localStorage.setItem('filter', JSON.stringify(newFilter));
    return newFilter;

  });

  const onChange: PaginationProps['onChange'] = (page) => {
    const newFilter = {
      ...filter,
      page: page
    }
    setFilter(newFilter);
    window.localStorage.setItem('filter', JSON.stringify(newFilter));
  };

  const getProducts = async () => {

    const result = await api.getProducts(filter.page, filter.searchValue.map(Number));

    if (result.data) {

      setProducts(result.data);

      if (result.total) {
        setTotal(result.total);
      }
      // if (page === 1) {
      //   setProducts(result.data);
      // } else {
      //   setProducts([
      //     ...products,
      //     ...result.data
      //   ]);
      // }
    }

  }

  // useEffect(() => {
  //
  //   window.localStorage.setItem('searchValue', JSON.stringify(searchValue));
  //   // window.localStorage.setItem('page', JSON.stringify(1));
  //   // setPage(1);
  //   void getProducts();
  //
  // }, [searchValue]);
  //
  // useEffect(() => {
  //
  //   window.localStorage.setItem('page', JSON.stringify(page));
  //   void getProducts();
  //
  // }, [page]);

  useEffect(() => {

    window.localStorage.setItem('filter', JSON.stringify(filter));
    void getProducts();

  }, [filter]);

  return (
    <CatalogContext.Provider value={{ filter, setFilter }}>
      <div className="container catalog">
        <h1>КАТАЛОГ СУВЕНИРНОЙ ПРОДУКЦИИ</h1>
        <div className='data'>
          <SearchString />
          <ProductList products={ products } />
          {
            total > 0 && total > 24  &&
              <Pagination
                align="center"
                size="small"
                total={total}
                pageSize={24}
                current={filter.page}
                // pageSizeOptions={[24, 48]}
                showSizeChanger={false}
                onChange={onChange}
                itemRender={itemRender}
              />
          }
        </div>

      </div>
    </CatalogContext.Provider>
  );
};

export default Catalog;
