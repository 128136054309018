import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer outline">
      <div className="copyrights">
        <span>2025 © Все права защищены</span>
      </div>
    </footer>
  );
}

export default Footer;