import { useContext } from "react";
import { NavLink } from "react-router-dom";

import {ShoppingCartOutlined} from "@ant-design/icons";

import { LayoutContext } from "../../pages/Layout/Layout";

import "./CartLink.css";

const CartLink = ({text=''}) => {

  const { cart } = useContext(LayoutContext);

  return (
    <NavLink to={"/cart"} className="header_nav_item">
      {text}
      <div className="cart-container">
        <ShoppingCartOutlined style={{ fontSize: '24px' }} />
        {cart.length > 0 && <span className="cart-count">{cart.length}</span>}
      </div>
    </NavLink>
  );
}

export default CartLink;