import { FC } from "react";

import ServiceList from "../../components/ServiceList/ServiceList";

import { services } from "../../data/services";

import "./Services.css";


const Services: FC = () => {

  return (
    <div className="container services">
      <h1>НАШИ УСЛУГИ</h1>
      <ServiceList services={services} />
    </div>
  );
};

export default Services;
