import { FC } from "react";

import { ServiceList as  ServiceListType } from "../../data/types";

// import ServiceItem.tsx from "../ServiceItem.tsx/ServiceItem.tsx";
import { Col, Row, Collapse, Space, Image } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import "./ServiceList.css";

interface ServiceListProps {
  services: ServiceListType;
}

const ServiceList: FC<ServiceListProps> = ({ services }) => (
  <Space direction="vertical">
    {
      services.map((item, index) => {

        return <Collapse
          key={index}
          className="service-item"
          collapsible="header"
          expandIconPosition="end"
          style={{alignContent: "center"}}
          items={[
            {
              key: index,
              label: <div className="service-header">
                <div className="service-item-info-image-container">
                  <Image
                    width={200}
                    src={item.imageUrl[0]}
                    preview={false}
                    className="service-item-info-image"
                  />
                </div>
                <div className="service-item-info-text">
                  <strong className="red">{`${item.title} `}</strong>
                  <span>{item.text}</span>
                </div>
              </div>,
              children: <Row gutter={[8, 8]}>
                <Image.PreviewGroup>
                  {
                    item.imageUrl.map((img, index) => {
                      return <Col
                        // span={8}
                        key={index}
                        xs={{ flex: '100%' }}
                        // sm={{  }}
                        md={{ flex: '50%' }}
                        // lg={{  }}
                        xl={{ flex: '25%' }}
                      >
                        <Image
                          src={img}
                          preview={{
                            mask: <><EyeOutlined style={{marginRight: '5px'}} /><span>{` Просмотр`}</span></>,
                            maxScale: 1.5
                          }}
                          className="service-list-image"
                        />
                      </Col>;
                    })
                  }
                </Image.PreviewGroup>
              </Row>,
            },
          ]}
        />
      })
    }
  </Space>
);

export default ServiceList;