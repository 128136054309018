import { FC } from "react";

import Slider from "../../components/Slider/SliderANTD";

import "./About.css";

const About: FC = () => {

  return (
    <>

      <div className="full-width-slider-container">
        <Slider />
      </div>

      <div className="container about">


        <div className="short-width-slider-container">
          <Slider />
        </div>

        <div className="about-info">

          <h1>РЕКЛАМНОЕ АГЕНСТВО АБСОЛЮТ&nbsp;МЕДИА</h1>

          <p className="about-text red">Сувенирная продукция - это эмоции.</p>
          <p className="about-text red mb25">
            Всем приятно получать подарки, пусть и самые незначительные.
          </p>
          <p className="about-text">
            Рекламное агентство «Абсолют Медиа» с 2008 года работает на рынке промопродукции и бизнес-подарков.
          </p>
          <p className="about-text mb25">
            У нас всегда широкий выбор подарков от недорогих, которые помогут «вписаться» даже в ограниченный бюджет до самых статусных персональных. Постоянный ассортимент продукции хранится на складах в Краснодаре, Москве, и Санкт-Петербурге. Компания работает, только с проверенными брендами. Мы постоянно разрабатываем и создаем принципиально новые промопродукты, поэтому всегда готовы отойти от стандартных схем и предложить заказчикам самые интересные и нестандартные варианты промопродукции и бизнес-подарков. А затем воплотить их в жизнь на самом высшем уровне. Для этого у агентства есть своя дизайн-студия и самые широкие производственные мощности. Выбор технологий производства и методов нанесения практически безграничен. Результат такой работы — уникальные решения для продвижения компаний.
          </p>
          <h2 className="mb25">
            СФЕРЫ НАШЕЙ КОМПЕТЕНЦИИ:
          </h2>
          <ul>
            <li className='my-list'>Разработка концепций дизайна сувенирной продукции и бизнес-подарков</li>
            <li className='my-list'>Разработка концепций дизайна полиграфической продукции</li>
            <li className='my-list'>Изготовление сувенирной и полиграфической продукци</li>
            <li className='my-list'>Широкоформатная и интерьерная печать.</li>
            <li className='my-list'>Наружная реклама</li>
            <li className='my-list'>Регистрация товарного знака, логотипа,бренда</li>
          </ul>

        </div>

      </div>
    </>
  );
};

export default About;
